import React, { useState, useRef } from "react";
import emailjs from "emailjs-com";
import { IoInformationCircleOutline } from 'react-icons/io5';

const Contact = () => {
  const form = useRef();

  const [formData, setFormData] = useState({
    lastname: "",
    name: "",
    email: "",
    message: "",
    validate: false, // Ajout du champ validate dans le state
  });

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;

    // Si le type est checkbox, utilisez la propriété checked pour mettre à jour la valeur du champ validate
    const fieldValue = type === "checkbox" ? checked : value;

    setFormData({
      ...formData,
      [name]: fieldValue,
    });
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!formData.name || !formData.lastname || !formData.email || !formData.message) {
      alert("Veuillez remplir tous les champs !");
      return;
    }

    if (!isValidEmail(formData.email)) {
      alert("Veuillez saisir une adresse e-mail valide !");
      return;
    }

    // Vérifiez si le champ validate est coché avant d'envoyer le courrier électronique
    if (formData.validate) {
      emailjs
        .sendForm("service_1x7ohxk", "template_nulup9i", event.target, "iTdojRuy31hkW0Eo0")
        .then(
          (result) => {
            setFormData({
              lastname: "",
              name: "",
              email: "",
              message: "",
              validate: false,
            });
            alert("Votre message a été envoyé avec succès !");
          },
          (error) => {
            alert("Une erreur s'est produite lors de l'envoi de votre message. Veuillez réessayer plus tard.");
          }
        );
    } else {
      alert("Votre consentement est nécessaire avant d'envoyer le message !");
    }
  };

  const [showInfo, setShowInfo] = useState(false);

  const handleInfoToggle = () => {
    setShowInfo(!showInfo);
  };

  return (
    <div>

      <div className="flex lg:flex-row flex-col lg:m-0 lg:mt-28 mt-20 items-center justify-center w-full">
        <form
          onSubmit={handleSubmit}
          ref={form}
          className="bg-white p-8 rounded-lg text-gray-700 drop-shadow-2xl lg:w-[40vw] w-3/4 my-4">
          <h1 className="md:text-5xl text-2xl font-medium my-6">Formulaire de contact</h1>
          <div className="flex md:flex-row flex-col w-full justify-stretch">
            <div className="w-full md:mr-3 m-0">
          <label
            className="block font-medium text-gray-700 mb-2"
            htmlFor="lastname"
          >
            Nom:
          </label>
          <input
            className="w-full border border-gray-400 text-gray-700 p-2 rounded-lg placeholder-gray-400 w-full focus:bg-slate-500 focus:caret-indigo-500 focus:border-none focus:text-white"
            type="text"
            name="lastname"
            value={formData.lastname}
            onChange={handleChange}
            placeholder="Nom"
            required
          />
          </div>
          <div className="w-full md:ml-3 m-0">
           <label
            className="block font-medium text-gray-700 mb-2"
            htmlFor="name"
          >
            Prénom:
          </label>
          <input
            className="w-full border border-gray-400 text-gray-700 p-2 rounded-lg placeholder-gray-400 w-full focus:bg-slate-500 focus:caret-indigo-500 focus:border-none focus:text-white"
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Prénom"
            required
          />
          </div>
          </div>
          <div className="my-4"></div>
          <label
            className="block font-medium text-gray-700 mb- "
            htmlFor="email"
          >
            Email:
          </label>
          <input
            className="w-full border border-gray-400 text-gray-700 p-2 rounded-lg placeholder-gray-400 focus:bg-slate-500 focus:caret-indigo-500 focus:border-none focus:text-white"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="exemple@gmail.com"
            required
          />
          <div className="my-4"></div>
          <label className="block font-medium text-gray-700 mb-2" htmlFor="message">Message:</label>
          <textarea
            className="w-full border border-gray-400 text-gray-700 p-2 rounded-lg placeholder-gray-400 resize focus:bg-slate-500 focus:caret-indigo-500 focus:border-none focus:text-white "
            name="message"
            value={formData.message}
            onChange={handleChange}
            rows={Math.ceil(formData.message.length / 18)}
            placeholder="Tape ton message ici..."
            required
          ></textarea>
          
          <div className="flex items-center mt-2">
               <input
            className="ml-2"
            type="checkbox"
            name="validate"
            checked={formData.validate}
            onChange={handleChange}
            required
          />
          <p className="pl-4 checked:text-red-600" for="validate">
          En soumettant ce formulaire, j'accepte que les informations saisies soient exploitées dans le cadre de la demande de contact.
          </p>
          </div>
          <div className="my-4"></div>
          <button className="bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600 lg:w-max w-full" type="submit">Envoyer</button>
        </form>
        {showInfo ? (
  <div className={`lg:w-2/5 md:w-4/5 w-11/12 sm:h-auto h-screen pt-6 ml-10 relative ${showInfo ? 'section-visible' : 'section-hidden'}`}>
  <button
    className={`text-blue-500 hover:text-blue-600 absolute ${showInfo ? 'top-0 left-0' : '-top-20 left-0 transform -translate-x-1/2 -translate-y-1/2'} transition-all duration-300 ${showInfo ? 'text-lg' : 'text-8xl'}`}
    onClick={handleInfoToggle}
  >
    <IoInformationCircleOutline />
  </button>
    <h2 className="text-2xl font-semibold">Information pour le bon traitement de votre message :</h2>
    <div className="leading-loose p-6">
   

   <p className="text-xl font-medium">Dans votre message merci d'inscrire si vous le souhaitez:</p>
   <div className="flex flex-col mt-5 ml-12">
     <ul className="list-decimal">
     <li>Un contact</li>
     <li>D'expliquer clairement votre demande</li>
     </ul>
     </div>
     <p className="text-md font-medium mt-8">*Merci de faire attention à l'adresse mail que vous renseignez et de vérifier qu'elle est valide.</p>
     <p className="text-md font-medium">*Si vous n'avez aucun retour : <a href="mailto:alexis.verdiere@gmail.com">Cliquez ici</a> ou envoyé votre demande a l'adresse alexis.verdiere@gmail.com</p>
 </div>
  </div>
) : (
  <div className={`lg:w-2/5 md:w-4/5 w-11/12 h-full pt-6 ml-10 relative flex items-center justify-center`}>
    <button
      className={`text-blue-500 hover:text-blue-600 ${showInfo ? 'text-lg' : 'text-8xl'}`}
      onClick={handleInfoToggle}
    >
      <IoInformationCircleOutline />
    </button>
  </div>
)}


      </div>
    </div>
  );
};



export default Contact;






