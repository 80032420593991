import '../../assets/css/projet.css'
import { layout } from "../../style";
import {Link} from "react-router-dom";
import React, {  useEffect } from 'react';
import { DiReact, DiHtml5, DiCss3} from "react-icons/di";
import { SiTailwindcss } from "react-icons/si";

const Projet = () => {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);
  return (
    <div className={` ${layout.flex} flex-wrap justify-center `}>
                
                <div  className="display_projet rounded-xl shadow-lg hover:shadow-2xl drop-shadow-lg hover:drop-shadow-2xl transition-all group/img group-hover/img:transition-all"> 
                <Link to="http://chezsabrinasnack.com/">
                <img alt="Projet Snack" className='group-hover/img:opacity-40 w-full group-hover/img:transition-all' src="https://burst.shopifycdn.com/photos/tray-with-fried-a-burger-and-a-glass-ramekin.jpg?width=925&format=pjpg&exif=1&iptc=1" />
                <div className="overlay p-4">
                    <h4 className='text-3xl font-old'>Snack Chez-Sabrina</h4>
                    <p className='text-2xl'>Conception du site internet d'un snack a saint-tropez en colaboration et au goût de la cliente.</p>
                    <div className='mt-8 flex items-center'>
                        <h4 className='text-xl'>Type de projet</h4>
                        <p className='rounded py-1 px-3 border-ThirdColor'> Professionnel </p>
                    </div>
                    <div className='mt-8 flex items-center'>
                        <h4 className='text-xl mr-4'>Langage Utlisé</h4>
                        <div className="flex text-4xl"> <DiHtml5 title='HTML'/> <DiCss3 title='CSS'/> </div>
                    </div>
                </div>
                </Link>
                </div>


                <div className="display_projet rounded-xl shadow-lg hover:shadow-2xl drop-shadow-lg hover:drop-shadow-2xl transition-all hover:cursor-pointer group/img group-hover/img:transition-all" >
                <Link to="/">
                <img alt="Mon portfolio" className='group-hover/img:opacity-40 w-full group-hover/img:transition-all' src="https://burst.shopifycdn.com/photos/hands-hold-a-cellphone-showing-the-shopify-home-page.jpg?width=925&format=pjpg&exif=1&iptc=1" />
                <div className="overlay p-4">
                    <h4 className='text-3xl font-old'>Portfolio</h4>
                    <p className='text-2xl'>Développement de mon portefeuille de compétences, réalisé en React.</p>
                    <div className='mt-8 flex items-center'>
                        <h4 className='text-xl'>Type de projet</h4>
                        <p className='rounded py-1 px-3 border-colorSecondary'> Personnel  </p>
                    </div>
                    <div className='mt-8 flex items-center'>
                        <h4 className='text-xl mr-4'>Langage Utlisé</h4>
                        <div className="flex text-4xl">  <DiReact title='REACT'/> <SiTailwindcss title='TailWind CSS'/> <DiHtml5 title='HTML'/> <DiCss3 title='CSS'/> </div>
                    </div>
                </div>
                </Link>
                </div>
                

            </div>
  )
}

export default Projet



