import React from 'react'
import {me_error} from "../../assets";
import {Link} from "react-router-dom"
import styles, { layout } from "../../style";
import { BsFillArrowRightCircleFill } from "react-icons/bs";

function Error() {
  return (
    <div className="h-screen">
        
    <div className={`${layout.flex} justify-center items-center` }>
    <img src={me_error} alt="me error" className="lg:p-8 p-0 lg:block hidden drop-shadow-2xl " />

    <div className="w-1/2 lg:m-0 mt-44 items-center h-sreen">
    <h2 className="text-6xl font-extrabold mb-10">404</h2>
    <h2 className="text-7xl font-black mb-10">Cette Page n'existe pas</h2>

    </div>

    </div>


    </div>
  )
}

export default Error
