import React, { useState } from 'react';
import styles, {layout} from "../../style"
import { Link } from "react-router-dom";
import {wave} from "../../assets/"


const offer = [
  {
    offerTitle: "Forfait Basique",
    offerDescription: [
      "Commencez à créer votre présence en ligne avec un portfolio professionnel."
    ],
    offerPrice: 929,
    offerOptions: [
      "Développement complet d'un portfolio élégant et moderne",
      "Conception sur mesure reflétant votre identité visuelle",
      "Mise en page adaptée à tous les appareils pour une expérience fluide non incluse",
      "Jusqu'à 5 pages soigneusement conçues pour présenter votre travail",
    ],
    cursor: "cursor-pointer",
    type: "ok"
  },
  {
    offerTitle: "Forfait Standard",
    offerDescription: [
      "Faites décoller votre activité avec un site web professionnel et percutant."
    ],
    offerPrice: 1429,
    offerOptions: [
      "Développement complet d'un site web d'entreprise moderne et fonctionnel",
      "Conception personnalisée pour refléter votre image de marque unique",
      "Assistance pendant 3 mois après le lancement pour un démarrage en douceur",
      "Mise en page adaptée à tous les appareils pour une accessibilité maximale",
      "Jusqu'à 20 pages pour présenter en détail vos produits et services",
    ],
    cursor: "cursor-pointer",
    type: "ok"
  },
  {
    offerTitle: "Forfait Avancé (Site Web et Application Mobile)",
    offerDescription: [
      "Propulsez votre présence avec un site web dynamique et une application mobile puissante."
    ],
    offerPrice: "Sur Devis",
    offerOptions: [
      "Développement complet d'un site web avec des fonctionnalités avancées pour un impact maximal",
      "Conception responsive offrant une expérience utilisateur optimale sur tous les appareils",
      "Création d'une application mobile professionnelle compatible avec iOS et Android",
      "Intégration de fonctionnalités sur mesure répondant à vos besoins spécifiques",
      "Solution complète pour une présence en ligne et mobile dominante",
    ],
    cursor: "cursor-pointer",
    type: "no"
  },
  
  ];


  const skillsServ = [
    {
      id: 'dev',
      title: 'Développement Web',
      icon: 'fas fa-code',
      description: 'Création de sites web fonctionnels en utilisant HTML, CSS et JavaScript de façon moderne.',
    },
    {
      id: 'app',
      title: 'App Web',
      icon: 'fas fa-laptop',
      description: 'Développement d\'applications web interactives et conviviales, accessibles via un navigateur.',
    },
    {
      id: 'design',
      title: 'UX/UI Design',
      icon: 'fas fa-paint-brush',
      description: 'Création d\'expériences utilisateur et de designs d\'interfaces intuitifs pour engager vos utilisateurs.',
    },
  ];


export default function Myservice() {
  const [hoveredItem, setHoveredItem] = useState(null);
  const handleMouseEnter = (itemId) => {
    setHoveredItem(itemId);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  return (
    <> 
     <img src={wave} id="wave" alt="" />
    <section className='bg-colorSecondary'>
    <div className="container mx-auto px-4">
  <h2 className="text-4xl md:text-7xl font-bold text-white mb-16 md:mb-44 text-center" data-aos="fade-up">
    Mes Prestations
  </h2>

  <div className="flex flex-col md:flex-row justify-center items-center gap-4 md:gap-10">
    {skillsServ.map((skill) => (
      <div
        key={skill.id}
        data-img={skill.id}
        className={`image-deco relative md:w-96 w-full h-72 md:h-80 bg-white color-secondary rounded-lg p-6 md:p-10 transition-all delay-150 duration-1000 hover:bg-[#0f172a] group/skills overflow-hidden ${hoveredItem === skill.id ? 'hover:shadow-lg' : ''}`}
        onMouseEnter={() => handleMouseEnter(skill.id)}
        onMouseLeave={handleMouseLeave}
      >
        <div className="absolute bottom-6 md:bottom-10">
          <i className={`text-3xl md:text-5xl transition-all delay-100  ${skill.icon} ${hoveredItem === skill.id ? 'group-hover/skills:text-white' : ''}`}></i>
          <h4 className={`text-xl md:text-3xl font-bold mt-2 md:mt-4 delay-100  transition-all ${hoveredItem === skill.id ? 'group-hover/skills:text-white' : ''}`}>{skill.title}</h4>

          <p className={`mt-2 text-base md:text-lg opacity-0 md:opacity-100 md:-translate-x-36 md:translate-y-36 transition-all duration-1000 ${hoveredItem === skill.id ? 'group-hover/skills:opacity-100 group-hover/skills:translate-x-0 group-hover/skills:translate-y-0 text-white pr-6 md:pr-10' : ''}`}>
            {skill.description}
          </p>
        </div>
      </div>
    ))}
  </div>
</div>

    <div className={`${layout.section} mt-8 pb-20`}>
        <h2 className={`${styles.titleSection}`}>Démarrer la création de votre site web</h2>

        <p className='pb-8 text-lg'>
          * Le mise en ligne de votre site est offerte
        </p>

        <div className={`${layout.flex}`} >
        {offer.map((offer) => (
             <div data-aos="fade-up" data-aos-delay='8000' className="w-full px-4 lg:w-1/2 z-0  ">
             <div
               className="shadow-pricing relative z-10 mb-10 rounded-xl border py-2 lg:py-10 px-8 sm:p-12 !pb-16 lg:py-10 lg:px-6 xl:p-12 h-full"
             >
              {offer.type === "no" ? (
              <div className={`absolute -right-3 -top-2 border p-3 bg-slate-700 border-red-600 rounded-md rotate-12 text-white`}>
                Pas encore disponible
              </div>
            ) : ( <></>)}
               <span className="text-primary mb-4 block text-xl font-semibold">
                 {offer.offerTitle}
               </span>
               {offer.type === "no" ? (
              <h2 className="mb-5 text-3xl font-semibold">
              <span className="text-3xl font-extrabold text-white"> {offer.offerPrice}</span>
              </h2>
            ) : (   <h2 className="mb-5 text-3xl font-semibold">
            <span className="text-3xl font-normal">A partir de</span> <span className="text-white font-extrabold"> {offer.offerPrice} €</span>
            </h2>)}
               <p
                 className="mb-8 border-b border-[#F2F2F2] pb-8 text-xl"
               >
                {offer.offerDescription}
               </p>
               <div className="mb-7">
               {offer.offerOptions.map((options, i) => (
                <ul>
                 <li className="mb-1 text-lg leading-loose list-disc" key={i}>
                   {options}
                 </li>
                 </ul>
                 ))}
                 
               </div>
               {offer.type === "no" ? (
                  <div
                    className={`${styles.btnOffers} block w-4/5 rounded-md border border-[#D4DEFF] p-4 text-center font-semibold absolute bottom-7 text-gray-600 hover:text-gray-600 ${offer.cursor}`}
                  >
                    Me contacter
                  </div>
                ) : (
                  <Link
                    to={`../contact`}
                    className={`${styles.btnOffers} block w-4/5 rounded-md border border-[#D4DEFF] p-4 text-center font-semibold absolute bottom-7 ${offer.cursor}`}
                  >
                    Me contacter
                  </Link>
                )}
               <div>
                 <span className="absolute right-0 top-7 z-[-1]">
                   <svg
                     width="77"
                     height="172"
                     viewBox="0 0 77 172"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                   >
                     <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                     <defs>
                       <linearGradient
                         id="paint0_linear"
                         x1="86"
                         y1="0"
                         x2="86"
                         y2="172"
                         gradientUnits="userSpaceOnUse"
                       >
                         <stop stop-color="#3056D3" stop-opacity="0.09" />
                         <stop offset="1" stop-color="#C4C4C4" stop-opacity="0" />
                       </linearGradient>
                     </defs>
                   </svg>
                 </span>
                 <span className="absolute right-4 top-4 z-[-1]">
                   <svg
                     width="41"
                     height="89"
                     viewBox="0 0 41 89"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                   >
                     <circle
                       cx="38.9138"
                       cy="87.4849"
                       r="1.42021"
                       transform="rotate(180 38.9138 87.4849)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="38.9138"
                       cy="74.9871"
                       r="1.42021"
                       transform="rotate(180 38.9138 74.9871)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="38.9138"
                       cy="62.4892"
                       r="1.42021"
                       transform="rotate(180 38.9138 62.4892)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="38.9138"
                       cy="38.3457"
                       r="1.42021"
                       transform="rotate(180 38.9138 38.3457)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="38.9138"
                       cy="13.634"
                       r="1.42021"
                       transform="rotate(180 38.9138 13.634)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="38.9138"
                       cy="50.2754"
                       r="1.42021"
                       transform="rotate(180 38.9138 50.2754)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="38.9138"
                       cy="26.1319"
                       r="1.42021"
                       transform="rotate(180 38.9138 26.1319)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="38.9138"
                       cy="1.42021"
                       r="1.42021"
                       transform="rotate(180 38.9138 1.42021)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="26.4157"
                       cy="87.4849"
                       r="1.42021"
                       transform="rotate(180 26.4157 87.4849)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="26.4157"
                       cy="74.9871"
                       r="1.42021"
                       transform="rotate(180 26.4157 74.9871)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="26.4157"
                       cy="62.4892"
                       r="1.42021"
                       transform="rotate(180 26.4157 62.4892)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="26.4157"
                       cy="38.3457"
                       r="1.42021"
                       transform="rotate(180 26.4157 38.3457)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="26.4157"
                       cy="13.634"
                       r="1.42021"
                       transform="rotate(180 26.4157 13.634)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="26.4157"
                       cy="50.2754"
                       r="1.42021"
                       transform="rotate(180 26.4157 50.2754)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="26.4157"
                       cy="26.1319"
                       r="1.42021"
                       transform="rotate(180 26.4157 26.1319)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="26.4157"
                       cy="1.4202"
                       r="1.42021"
                       transform="rotate(180 26.4157 1.4202)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="13.9177"
                       cy="87.4849"
                       r="1.42021"
                       transform="rotate(180 13.9177 87.4849)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="13.9177"
                       cy="74.9871"
                       r="1.42021"
                       transform="rotate(180 13.9177 74.9871)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="13.9177"
                       cy="62.4892"
                       r="1.42021"
                       transform="rotate(180 13.9177 62.4892)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="13.9177"
                       cy="38.3457"
                       r="1.42021"
                       transform="rotate(180 13.9177 38.3457)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="13.9177"
                       cy="13.634"
                       r="1.42021"
                       transform="rotate(180 13.9177 13.634)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="13.9177"
                       cy="50.2754"
                       r="1.42021"
                       transform="rotate(180 13.9177 50.2754)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="13.9177"
                       cy="26.1319"
                       r="1.42021"
                       transform="rotate(180 13.9177 26.1319)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="13.9177"
                       cy="1.42019"
                       r="1.42021"
                       transform="rotate(180 13.9177 1.42019)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="1.41963"
                       cy="87.4849"
                       r="1.42021"
                       transform="rotate(180 1.41963 87.4849)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="1.41963"
                       cy="74.9871"
                       r="1.42021"
                       transform="rotate(180 1.41963 74.9871)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="1.41963"
                       cy="62.4892"
                       r="1.42021"
                       transform="rotate(180 1.41963 62.4892)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="1.41963"
                       cy="38.3457"
                       r="1.42021"
                       transform="rotate(180 1.41963 38.3457)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="1.41963"
                       cy="13.634"
                       r="1.42021"
                       transform="rotate(180 1.41963 13.634)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="1.41963"
                       cy="50.2754"
                       r="1.42021"
                       transform="rotate(180 1.41963 50.2754)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="1.41963"
                       cy="26.1319"
                       r="1.42021"
                       transform="rotate(180 1.41963 26.1319)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="1.41963"
                       cy="1.4202"
                       r="1.42021"
                       transform="rotate(180 1.41963 1.4202)"
                       fill="#3056D3"
                     />
                   </svg>
                 </span>
               </div>
             </div>
           </div>
        ))}
        </div>

    </div>
    </section>
  

    </>
  )
}
