import React, { useState, useEffect } from 'react';
import styles, { layout } from "../../style";
import {me_about} from "../../assets";
import Tools from './Tools.jsx'
import Footer from '../Footer/Footer';
import Skills from "./Skills.jsx";
import Projet from '../Portfolio/Projet.jsx';



  // Date d'aujourd'hui
  const today = new Date();
  
  // Date début dev
  const otherDate = new Date('2020-09-01');
  
  // Calcul de l'écart en années
  const yearDiff = today.getFullYear() - otherDate.getFullYear();

function About() {
  const [age, setAge] = useState(0);
  useEffect(() => {
    const currentDate = new Date();
    const birthDate = new Date('2002-07-22');
    const ageInMilliseconds = currentDate.getTime() - birthDate.getTime();
    const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);
    setAge(Math.floor(ageInYears));
  }, []);
  return (
    <div className="">

      <div className={`${layout.section_page}`}>
          <h1 className={`${styles.title}`}>A propos</h1>
        </div>
        <div className={` ${layout.section_pres} relative text-white`}>
          <h2 className="text-5xl font-bold mb-8">Présentation</h2>    

        
        <p className='w-3/5 text-lg max-[1250px]:w-full'>Bonjour, je m'appelle Alexis et je suis un développeur web passionné de {age} ans. Avec ma solide connaissance en développement web et en nouvelles technologies, je suis capable de concevoir et de développer des sites web professionnels et modernes. Outre mes compétences techniques, je suis aussi un grand communicateur et je travaille bien en équipe pour assurer la réussite de chaque projet.</p>
    <br />
        <p className='w-3/5 text-lg max-[1250px]:w-full'>En plus de mes compétences en développement web, je suis également passionné par le design pour le web et je suis toujours à jour sur les dernières tendances en matière de design. Je suis également très conscient de l'importance de l'accessibilité et de l'optimisation pour les moteurs de recherche dans le design des sites web.</p>
   
        <img src={me_about} alt="me" className="absolute top-[-4em] right-36 w-fit min-[1251px]:block hidden"/>
    

      </div>

      
      <div className={` ${layout.section}`}>
                        <h2 className={` ${styles.titleSection} `} >Mes Projets</h2>
                           <Projet/>
                    </div>

      <div className={`${layout.section}`}>
      <h2 className={`${styles.titleSection}`}>Mes compétences</h2>
      <div className="md:w-2/5 w-full mb-4">
        <h1 className="text-6xl font-bold text-violet">{yearDiff}+ <span className="text-lg font-bold w-full">Années d'expérience de travail</span></h1>
      </div>
      <div className={`${layout.flex}`}>
          <Skills/>
        </div>
      </div>





      <div className={`${layout.section}`}>
        <Tools/>
      </div>






<Footer/>

    </div>
  )
}

export default About
