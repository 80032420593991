import React, { useState, useEffect } from 'react';
import {Routes, Route} from "react-router-dom"
import Service from "./components/Services/Service.jsx"
import Portfolio from "./components/Portfolio/Portfolio.jsx"
import About from "./components/About/About.jsx"
import Contact from "./components/Contact/Contact.jsx"
import Error from "./components/404/Error.jsx"
import Navbar from "./components/Navbar/Navbar.jsx"
import ScrollToTop from './components/ScrollToTop';

function App() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <Navbar />
    <div className='bg-slate-900 text-white overflow-hidden ease-linear	'>
    <ScrollToTop />
        <Routes>
            <Route exact path='/' element={ <Portfolio/>} />
            <Route exact path='/service' element={ <Service/>} />
            <Route exact path='/about' element={ <About/>} />
            <Route exact path='/contact' element={ <Contact/>} />
            <Route  path='*' element={ <Error/>} />
        </Routes>
    </div>
    </>
  )
}

export default App


