import React, { useEffect } from 'react'
import  styles,{  layout } from "../../style";
import "../../index.css";
import AOS from "aos";
import 'aos/dist/aos.css';
import Footer from '../Footer/Footer';
import Myservice from './myservice';


const steps = [
  {
    stepSteps: "1er",
    stepTitle: "Réunir les informations nécessaires",
    stepDescription: [
      "Comprendre vos objectifs et le public ciblé",
      "Déterminer les fonctionnalités et les pages du site",
      "Collecter toutes les données et les médias nécessaires (textes, images, etc.)"
    ]
  },
  {
    stepSteps: "2éme",
    stepTitle: "Créer un plan de site",
    stepDescription: [
      "Organiser les pages et les fonctionnalités en un plan de site logique",
      "Déterminer la navigation et la structure du site"
    ]
  },
  {
    stepSteps: "3éme",
    stepTitle: "Design du site",
    stepDescription: [
      "Créer des maquettes de l'apparence et de la disposition",
      "Votre approbation sera nécessaire pour cette étape"
    ]
  },
  {
    stepSteps: "4éme",
    stepTitle: "Développement du site",
    stepDescription: [
      "Convertir les maquettes en un site fonctionnel en utilisant un langage de programmation pour développer les pages et les fonctionnalités",
      "Tester et déboguer le site pour s'assurer qu'il fonctionne correctement"
    ]
  },
  {
    stepSteps: "Dernière",
    stepTitle: "Lancement du site",
    stepDescription: [
      "Mettre en ligne le site sur un serveur web pour qu'il soit accessible sur Internet",
      "Promouvoir le site en utilisant des techniques de référencement (SEO) et de marketing en ligne"
    ]
  },
];

export default function Service() {
  useEffect(()=> {
    AOS.init({duration: 1500});
},[])



  return (
    <div>

             
      <div className={`${layout.section_page}`}>
         <h1 className={`${styles.title}`}>Mes services</h1>
      </div>

      <div data-aos="fade-up" data-aos-delay="20000" className={` ${layout.section_pres} text-white/80 overflow-hidden`}>
      <h2 className="text-4xl font-bold mb-8">Les avantages d’avoir son propre site WEB </h2>
      <div className={`${layout.flex} flex-wrap justify-between mt-20`}>
      <div className={`${layout.service}`}>
        <h2 className="text-black/80 text-2xl mb-2" data-aos="fade-down">Visibilité</h2>
        <p data-aos="fade-right">Un site internet vous permet d'être visible sur la toile, ce qui peut être très utile pour votre entreprise ou votre activité. Vous pouvez ainsi vous faire connaître auprès d'un public plus large et attirer de nouveaux clients.</p>
      </div>
      <div className={`${layout.service}`}>       
      <h2 className="text-black/80 text-2xl mb-2" data-aos="fade-down">Crédibilité</h2>
        <p data-aos="fade-right">Avoir un site internet professionnel peut renforcer votre crédibilité auprès de vos clients et de votre public. Cela montre que vous êtes sérieux et que vous prenez votre activité au sérieux.
</p>
      </div>
      <div className={`${layout.service}`}>       
      <h2 className="text-black/80 text-2xl mb-2" data-aos="fade-down">Communication</h2>
        <p data-aos="fade-right">Un site internet vous permet de communiquer de manière simple et efficace avec votre public. Vous pouvez y publier des actualités, des offres spéciales, des témoignages de clients, etc.</p>
      </div>
      <div className={`${layout.service}`}>      
       <h2 className="text-black/80 text-2xl mb-2" data-aos="fade-down">Vente en ligne</h2>
        <p data-aos="fade-right">Si vous vendez des produits ou des services, avoir un site internet vous permet de les proposer en ligne et de toucher un public plus large. Cela peut être particulièrement utile si vous avez une entreprise locale et que vous souhaitez étendre votre zone de chalandise.</p>
      </div>
      <div className={`${layout.service}`}>      
       <h2 className="text-black/80 text-2xl mb-2" data-aos="fade-down">Analyse de données</h2>
        <p data-aos="fade-right">Un site internet vous permet de suivre l'activité de votre site grâce à des outils d'analyse de données (comme Google Analytics). Vous pouvez ainsi connaître le nombre de visiteurs sur votre site, leur provenance, les pages qu'ils ont consultés, etc. Cela peut vous aider à mieux comprendre votre public et à adapter votre stratégie de communication.
</p>
      </div>
      <div className={`${layout.service}`}>
  <h2 className="text-black/80 text-2xl mb-2" data-aos="fade-down">Expansion géographique</h2>
  <p data-aos="fade-right">Un site internet vous offre la possibilité de vous étendre géographiquement et d'atteindre des clients potentiels en dehors de votre région ou pays d'origine. Vous pouvez proposer vos produits ou services à un public international, ce qui peut considérablement augmenter vos opportunités commerciales.</p>
</div>                 
      </div>
        </div>


        <div className={` ${layout.section} `}>
  <h2 className="lg:text-5xl text-4xl font-extrabold mb-28">Les étapes du développement de votre site internet</h2>
  <div className="ml-4 md:ml-20 flex flex-col">
  {steps.map((step, index) => (
    <div key={index} className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 mb-8 px-2">
      <ol className="relative border-l border-gray-600 w-[90%] md:w-[30vw] basis-2">                  
        <li className="ml-4 mb-6 h-fit w-full max-w-4xl">
          <div className="absolute w-3 h-3 rounded-full mt-1.5 -left-1.5 border border-gray-300 bg-gray-700"></div>
          <time className="mb-1 font-normal leading-none text-gray-400 dark:text-gray-500">{step.stepSteps} étapes</time>
          <h3 className="text-2xl font-semibold text-gray-900 dark:text-white">{step.stepTitle}</h3>
          {step.stepDescription.map((desc, i) => (
            <p className="text-base md:text-lg font-normal text-gray-400" key={i}>{desc}</p>
          ))}
        </li>
      </ol>
    </div>
  ))}
</div>


</div>

            <Myservice/>


        <Footer/>

  
</div>

  )
}
