import React, { useState } from 'react';

const Skills = () => {
  const [hoveredItem, setHoveredItem] = useState(null);

  const skillsData = [
    { id: 'design', title: 'Web Design', icon: 'fas fa-drafting-compass', description: 'Le web design consiste à créer des interfaces visuellement attrayantes et accessible pour les sites et applications Web.' },
    { id: 'front', title: 'Frontend', icon: 'fas fa-desktop', description: 'Le frontend implique le développement de l\'interface utilisateur et l\'expérience utilisateur d\'un site Web.' },
    { id: 'back', title: 'Backend', icon: 'fas fa-bolt', description: 'Le backend gère la logique du serveur, la base de données et d\'autres fonctionnalités invisibles pour les utilisateurs finaux.' },
  ];

  const handleMouseEnter = (itemId) => {
    setHoveredItem(itemId);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  return (
    <div className="flex flex-wrap justify-center items-center m-auto gap-6 md:gap-10">
      {skillsData.map((skill) => (
        <div
          key={skill.id}
          data-img={skill.id}
          className={`image-deco relative lg:w-96 w-full md:h-80 h-52 bg-white color-secondary rounded-lg p-6 md:p-10 transition-all delay-150 duration-1000 hover:bg-[#2563EB] group/skills overflow-hidden ${hoveredItem === skill.id ? 'hover:shadow-lg' : ''}`}
          onMouseEnter={() => handleMouseEnter(skill.id)}
          onMouseLeave={handleMouseLeave}
        >
          <div className="absolute bottom-6 md:bottom-10">
            <i className={`text-4xl md:text-5xl transition-all delay-100 ${skill.icon} ${hoveredItem === skill.id ? 'group-hover/skills:text-white' : ''}`}></i>
            <h4 className={`text-xl md:text-4xl font-bold mt-2 md:mt-4 delay-100 transition-all ${hoveredItem === skill.id ? 'group-hover/skills:text-white' : ''}`}>{skill.title}</h4>

            <p className={`mt-1 text-sm md:text-lg opacity-0 -translate-x-28 md:translate-x-0 translate-y-28 transition-all duration-1000 group-hover/skills:opacity-100 group-hover/skills:translate-x-0 group-hover/skills:translate-y-0 text-white ${hoveredItem === skill.id ? 'pr-6' : 'pr-10'}`}>
              {skill.description}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Skills;
