import React, { useState, useEffect } from 'react';
import TypeWriterEffect from 'react-typewriter-effect';
import styles, { layout } from "../../style";
import { me } from "../../assets";
import { Link } from "react-router-dom";
import AOS from "aos";
import 'aos/dist/aos.css';
import {test} from "../../assets";
import Projet from './Projet.jsx';
import ScrollButton from '../ScrollButton.jsx';
import Footer from '../Footer/Footer';
import { BsFillArrowRightCircleFill } from "react-icons/bs";


const Portfolio = () => {

  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);

  const [age, setAge] = useState(0);




  useEffect(() => {
    const currentDate = new Date();
    const birthDate = new Date('2002-07-22');
    const ageInMilliseconds = currentDate.getTime() - birthDate.getTime();
    const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);
    setAge(Math.floor(ageInYears));
  }, []);

  return (
    <div>
          <div
        className={`md:w-full w-9/10 h-screen md:m-0 md:ml-10 m-auto ml-5 flex flex-col items-center justify-center text-center color-title`}
      >
        <h2
          className="md:text-7xl text-5xl font-semibold text-center "
        >
          <TypeWriterEffect
            startDelay={1300}
            hideCursorAfterText
            cursorColor="#3F3D56"
            multiText={['Bonjour, je suis Alexis', `Un jeune développeur passionné `,"En quête d'expérience...",'Bienvenue sur mon portfolio']}
            typeSpeed={20}
          />
        </h2>
        <ScrollButton />
      </div>
      <div className={` ${layout.section} ${layout.flex}  flex-col-reverse`}>
        <div className="w-full" data-aos="fade-up">
          <h3 className="md:text-5xl text-4xl font-bold mb-10 color-title">A Propos</h3>
          <p className="paragraph">
            Je suis un développeur web passionné, j'adore développer des sites et applications web, qu'il s'agisse de portfolio pour particuliers ou de sites vitrines pour des professionnels. J'aime explorer les dernières technologies et mettre en œuvre des idées novatrices pour créer des produits uniques. En dehors du travail, je suis passionné par le cinéma, les jeux vidéo et la découverte des nouvelles avancées technologiques. Si vous recherchez un développeur créatif et enthousiaste pour votre prochain projet, n'hésitez pas à <Link to="contact"> me contacter </Link>!
          </p>
          <Link data-aos="fade-up" type="button" to="/about" className={`${styles.button} flex w-fit items-center ease-in group/btn transition-all`}>En savoir plus sur moi <BsFillArrowRightCircleFill className="opacity-0 group-hover/btn:opacity-100 group-hover/btn:ease-in -translate-x-10 transition-all ml-4 ease-in group-hover/btn:translate-x-0 text-white"/></Link>
        </div>
        <div className="lg:mr-24 mt-4 m-auto" >
          <div data-aos="fade-up" className="w-[250px] bg-colorSecondary h-[250px] rounded-full rounded flex justify-center items-center m-6">
            <img src={me} alt="" />
          </div>
        </div>
      </div>



                    <div className={`${layout.section}`}>
      <h2 className={`${styles.titleSection} !text-white`} data-aos="fade-up">Une idée de projet ?</h2>
      <div  className={`${layout.flex} items-center`}>
        <div>
        <p data-aos="fade-up" className="paragraph">Vous recherchez un développeur de site web professionnel qualifié pour mener à bien votre projet ? Ne regardez pas plus loin! Contactez-moi maintenant pour discuter de vos besoins et comment je peux contribuer à faire de votre vision une réalité. Grâce à mon expérience et mes compétences, je suis capable de vous proposer des solutions personnalisées et de qualité pour votre site web. N'hésitez pas à me contacter pour tout renseignement complémentaire ou pour obtenir un devis gratuit.</p>
        <Link data-aos="fade-up" type="button" to="/service" className={`${styles.button} flex w-fit items-center ease-in group/btn transition-all`}>Commencer un projet <BsFillArrowRightCircleFill className="opacity-0 group-hover/btn:opacity-100 group-hover/btn:ease-in -translate-x-10 transition-all ml-4 ease-in group-hover/btn:translate-x-0 text-white"/></Link>
        </div>
        <img data-aos="fade-up" className="w-1/2 skew-x-[2deg] min-[1251px]:block hidden" src={test} />

      </div>
      
      </div>
                    <Footer/>
      
        </div>
    )
}

export default Portfolio

