import React, { useState, useRef } from "react";
import {AiFillGithub, AiFillLinkedin} from "react-icons/ai";
import {SiGmail} from "react-icons/si";
import AOS from "aos";
import {footer} from "../../style";
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import emailjs from "emailjs-com";
import { BsFillTelephoneFill } from "react-icons/bs";
import {CgMail} from "react-icons/cg";
import {wavefooter} from "../../assets/"

const Footer = () => {

    const form = useRef();

    const [formData, setFormData] = useState({
      lastname:"",
      name: "",
      email: "",
      message: "",
    });
  
    const handleChange = (event) => {
      setFormData({
        ...formData,
        [event.target.name]: event.target.value
      });
    };
  
    const handleSubmit = (event) => {
      event.preventDefault();
      if (!formData.name  || !formData.lastname || !formData.email || !formData.message) {
        alert("Veuillez remplir tous les champs !");
        return;
      }
    
      emailjs.sendForm('service_1x7ohxk', 'template_nulup9i', event.target, 'iTdojRuy31hkW0Eo0')
        .then((result) => {
          setFormData({
            lastname:"",
            name: "",
            email: "",
            message: "",
          });
          alert("Votre message a été envoyé avec succès !");
        }, (error) => {
          alert("Une erreur s'est produite lors de l'envoi de votre message. Veuillez réessayer plus tard.");
        });
    };
  
  
    
    useEffect(()=> {
        AOS.init({duration: 1500});
    },[])
    return (
        <>
            <img className="w-screen" src={wavefooter}/>
        <div className="bg-black py-6">
        <div className="flex lg:flex-row m-auto flex-col justify-center mt-32 items-center">
        <div className="flex flex-col text-white lg:w-1/3 w-[80vw]">
        <h2 className="text-4xl">Me contacter</h2>
        <div className="text-5xl md:p-5 p-0">
        <a href="tel:+33660941231" className="flex mt-2 w-fit group/icon"><BsFillTelephoneFill className="rounded-full border-2 p-2 group-hover/icon:border-dotted"/> <span className="text-xl ml-5 items-end flex">06.60.94.12.31</span></a>
        <a href="mailto:contact@alexisverdiere.fr" className="flex mt-7 w-fit group/icon"><CgMail className="rounded-full border-2 p-2 group-hover/icon:border-dotted"/> <span className="text-xl ml-5 items-end flex">contact@alexisverdiere.fr</span></a> 
        </div>
      </div>
        <form   
        onSubmit={handleSubmit}
        ref={form}
        className="lg:w-1/3 w-3/4 lg:mt-0 m-8"
      >
        <div className="flex md:flex-row flex-col w-full justify-stretch z-[9999]">
          <div className="w-full mr-3">
        <label
          className="block font-medium mb-2"
          htmlFor="lastname"
        >
          Nom:
        </label>
        <input
          className={footer.input}
          type="text"
          name="lastname"
          value={formData.lastname}
          onChange={handleChange}
          placeholder="Nom"
          required
        />
        </div>
        <div className="w-full ml-3">
         <label
          className="block font-medium mb-2"
          htmlFor="name"
        >
          Prénom:
        </label>
        <input
          className={footer.input}
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          placeholder="Prénom"
          required
        />
        </div>
        </div>
        <div className="my-4"></div>
        <label
          className="block font-medium mb-2"
          htmlFor="email"
        >
          Email:
        </label>
        <input
          className={footer.input}
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="exemple@gmail.com"
          required
        />
        <div className="my-4"></div>
        <label className="block font-medium mb-2" htmlFor="message">Message:</label>
        <textarea
          className={footer.input}
          name="message"
          value={formData.message}
          onChange={handleChange}
          rows={Math.ceil(formData.message.length / 18)}
          placeholder="Tape ton message ici..."
          required
        ></textarea>
        <div className="my-4"></div>
        <button className="bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-700 w-full" type="submit">Envoyer</button>
      </form>
       </div>
       <div className="mt-32">
         <hr className="lg:w-1/2 w-4/5 flex m-auto"/>
        <footer className="flex lg:w-1/2 w-4/5 m-auto text-4xl justify-around my-10">
            <a data-aos="fade-up" href="https://github.com/Alexis-Verdiere" rel="noreferrer" target="_blank" ><AiFillGithub/></a>
            <a data-aos="fade-up" href="https://www.linkedin.com/in/alexis-verdiere/" rel="noreferrer" target="_blank"><AiFillLinkedin data-descr="Linkedin"/></a>
            <a data-aos="fade-up" href="mailto:contact@alexisverdiere.fr" rel="noreferrer" target="_blank"><SiGmail data-descr="Gmail"/></a>
        </footer>
        <p className="md:m-14 m-4 text-center text-xl">Copyright © 2023 - Alexis Verdiere - Développeur web</p>
       </div>
       </div>
       </>
    )
}

export default Footer