import React from 'react';
import { DiReact, DiHtml5, DiCss3, DiGithubBadge, DiJavascript1, DiVisualstudio, DiWindows} from "react-icons/di";
import { SiPostman } from "react-icons/si";
import { SiTailwindcss } from "react-icons/si";
import { IoLogoFigma } from "react-icons/io5";
import styles from "../../style";

const iconsDev = [
  {
    iconsTitle: "Html",
    icons: <DiHtml5/>,
    pourcentage: 90,
  },
    {
    iconsTitle: "Css",
    icons: <DiCss3/>,
    pourcentage: 90,
  },
  {
    iconsTitle: "TailwindCss",
    icons: <SiTailwindcss/>,
    pourcentage: 60,
  },
  {
    iconsTitle: "JS",
    icons: <DiJavascript1/>,
    pourcentage: 50,
  },
    {
    iconsTitle: "React",
    icons: <DiReact/>,
    pourcentage: 50,
  },
]

const iconsTools = [
  {
    iconsTitle: "Windows",
    icons: <DiWindows/>,
    pourcentage: 95,
  },
  {
    iconsTitle: "Figma",
    icons: <IoLogoFigma/>,
    pourcentage: 50,
  },
  {
    iconsTitle: "Visual studio",
    icons: <DiVisualstudio/>,
    pourcentage: 75,
  },
  {
    iconsTitle: "Github",
    icons: <DiGithubBadge/>,
    pourcentage: 50,
  },
  {
    iconsTitle: "Postman",
    icons: <SiPostman/>,
    pourcentage: 40,
  },

]

const ProgressBar = ({ title, pourcentage, Icon }) => {
  
  return (
    <div className="flex flex-col items-center md:flex-row md:items-center mb-4 md:mb-6 mx-auto justify-center my-5 content-center">
      <div className="w-full md:w-4/5">
        <div className="text-xl font-semibold uppercase flex items-center justify-center md:justify-start">
          <span className="text-3xl p-2">{Icon}</span>
          {title}
        </div>
        <div className="h-4 bg-gray-200 rounded overflow-hidden mt-2 md:mt-3">
          <div className="h-full bg-colorSecondary rounded overflow-hidden" style={{ width: `${pourcentage}%` }}></div>
        </div>
      </div>
      <div className="mt-2 md:mt-0 ml-0 md:ml-4">{pourcentage}%</div>
    </div>

  );
};


const Tools = () => {
  
  return (
    <>
    <h3 className={styles.titleSection}>Mes outils</h3>
    <div className="flex md:flex-row flex-col">      
        <div className={`w-4/5 p-2 md:p-8 justify-center md:m-0 m-auto`}>
        {iconsDev.map((item, index) => (
        <ProgressBar
          key={index}
          title={item.iconsTitle}
          pourcentage={item.pourcentage} // Générez un pourcentage aléatoire (à remplacer par la valeur réelle)
          Icon={item.icons}
        />
      ))}
          </div>
          <div className={`w-4/5 p-2 md:p-8 justify-center md:m-0 m-auto`}>
        {iconsTools.map((item, index) => (
        <ProgressBar
          key={index}
          title={item.iconsTitle}
          pourcentage={item.pourcentage} // Générez un pourcentage aléatoire (à remplacer par la valeur réelle)
          Icon={item.icons}
        />
      ))}
          </div>
          </div>
         </>
  );
};

export default Tools;
