const styles = {
    menu: "hover:bg-black max-[1450px]:w-screen max-[1450px]:basis-full max-[1450px]:h-[50vh] flex items-center justify-center transition-all w-[40%] p-[10%] mx-[5%] h-[40vh] rounded-2xl border-2 border-black hover:text-white hover:border-none",
    button : "rounded-lg p-4 border-colorSecondary hover:bg-[#2563EB] hover:text-white transition-all",
    title: "lg:text-6xl text-5xl font-extrabold lg:h-[50vh] h-[20vh] md:m-auto flex md:items-center mx-0",
    titleSection: "lg:text-6xl text-5xl font-bold m-auto pb-36 text-center mt-24",
    btnOffers: "rounded-lg p-4 hover:bg-white hover:text-[#2563EB] transition-all",
    icons: "text-7xl basis-20 m-2 md:m-12",
};

export const layout = {
    section: "m-auto  lg:w-3/4 w-full mt-44  p-8",
    section_page: "lg:m-32 mx-8 mt-32",
    flex: "flex lg:flex-row flex-col justify-arround",
    card_project: " rounded-lg p-4 bg-colorSecondary h-[220px] mb-8 relative hover:-translate-y-4 hover:bg-colorSecondaryhover transition-all duration-500 w-full",
    card_project1: `md:w-[64%]`,
    card_project2: `md:w-[33%] `,
    section_pres: "w-[80%] max-[600px]:w-[90%] m-auto p-20 max-[600px]:p-4 bg-colorSecondary shadow-xl min-h-[400px] rounded-[2vw]",
    service: "md:w-[45%] mb-20",
    container : "bg-colorSecondary w"
};

export const text = {
    colorGradient : "text-transparent bg-clip-text bg-gradient-to-r from-yellow-400 to-blue-700"
}


export const services = {
    card: "bg-[#333] flex flex-col h-[45vh] md:w-[25vw] w-3/4 rounded-lg mt-5 text-left transition ease-in-out "
};

export const navbare = {
    link: "text-2xl content-center link link-underline link-underline-black w-fit transition-all text-white",
    activeLink: "link-underline-active link-underline-black",
}

export const footer = {
    input: "w-full border border-gray-400 p-2 bg-transparent rounded-lg w-full focus:bg-gray-300/20 focus:caret-indigo-500"
}

export default styles;