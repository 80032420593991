import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { navbare } from '../../style';
import "../../../src/assets/css/navbar.css";

function Navbar() {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <div className="bg-gray-900 backdrop-filter backdrop-blur-sm bg-opacity-50 fixed top-0 left-0 w-full z-50">
      <div className="flex justify-between items-center py-4 px-6">
        <Link onClick={closeMenu} to="/" >
          <span className="ml-2 font-bold text-xl text-white">Alexis Verdiere</span>
        </Link>
        <div className={`hidden md:flex space-x-6 ${isMenuOpen ? 'hidden' : 'flex'}`}>
          <Link className={`${navbare.link} ${location.pathname === '/about' ? navbare.activeLink : ''}`} to="/about">
            A Propos
          </Link>
          <Link className={`${navbare.link} ${location.pathname === '/service' ? navbare.activeLink : ''}`} to="/service">
            Mes prestations
          </Link>
          <Link className={`${navbare.link} ${location.pathname === '/contact' ? navbare.activeLink : ''}`} to="/contact">
            Contact
          </Link>
        </div>
        <div className="md:hidden flex items-center">
          <button
            onClick={toggleMenu}
            className="text-white focus:outline-none"
            aria-label="Menu"
          >
            <i className={`fas ${isMenuOpen ? 'fa-times' : 'fa-bars'} text-2xl`} />
          </button>
        </div>
      </div>
      {isMenuOpen && (
        <div className="md:hidden">
          <Link
            onClick={closeMenu}
            className={`block px-4 py-2 ${navbare.link} ${location.pathname === '/about' ? navbare.activeLink : ''}`}
            to="/about"
          >
            A Propos
          </Link>
          <Link
            onClick={closeMenu}
            className={`block px-4 py-2 ${navbare.link} ${location.pathname === '/service' ? navbare.activeLink : ''}`}
            to="/service"
          >
            Mes prestations
          </Link>
          <Link
            onClick={closeMenu}
            className={`block px-4 py-2 ${navbare.link} ${location.pathname === '/contact' ? navbare.activeLink : ''}`}
            to="/contact"
          >
            Contact
          </Link>
        </div>
      )}
    </div>
  );
}

export default Navbar;
