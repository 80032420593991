import React, { useState } from 'react';
import { FaArrowDown } from 'react-icons/fa';

function ScrollButton() {
  const [showButton, setShowButton] = useState(true);

  const handleScroll = () => {
    window.scrollTo({
      top: window.innerHeight,
      behavior: 'smooth',
    });
  };

  const handleScrollButtonVisibility = () => {
    if (window.pageYOffset > window.innerHeight / 1.9) {
      setShowButton(false);
    } else {
      setShowButton(true);
    }
  };

  window.addEventListener('scroll', handleScrollButtonVisibility);

  return (
    <button
      className={`fixed bottom-0 mb-6 mr-6 rounded-full bg-gray-600 bg-opacity-50 p-3 cursor-pointer animate-bounce ${
        showButton ? 'opacity-100 visible' : 'opacity-0 invisible'
      }`}
      onClick={handleScroll}
    >
        <FaArrowDown className="text-white text-xl" />
    </button>
  );
}

export default ScrollButton;
